
// ACCORDION

.accordion
	margin: 0px
	padding: 0px
	.accordion-li
		margin-bottom: 10px
		position: relative
		list-style: none

		.accordion-content
			display: none
			padding: 30px 20px
			.items
				display: flex
				flex-flow: row wrap
				margin: -20px

				a
					padding: 20px
					width: 33.3%
					@media screen and (max-width: $md)
						width: 50%
					@media screen and (max-width: $xs)
						width: 100%
					img
						max-width: 35px
					p
						font-size: 14px
						padding-left: 10px
						margin: 0

			p
				margin-top: 0px
		.accordion-a
			width: 100%
			display: block
			cursor: pointer
			line-height: 2
			@include fluid('font-size',15, 18)
			line-height: 1
			color: #fff
			user-select: none
			padding: 10px 15px
			background: var(--color-gray-4)
			color: var(--color-dark-2)
			&.active
				background: var(--color-dark-2)
				color: #fff
				&:after
					border-right: 2px solid #fff
					border-bottom: 2px solid #fff

	.accordion-a, .accordion .accordion-a:hover, .accordion .accordion-a:active
		color: #636363

	.accordion-a:after
		width: 12px
		height: 12px
		border-right: 2px solid var(--color-dark-2)
		border-bottom: 2px solid var(--color-dark-2)
		position: absolute
		right: 15px
		content: " "
		top: 12px
		transform: rotate(-45deg)
		-webkit-transition: all 0.2s ease-in-out
		-moz-transition: all 0.2s ease-in-out
		transition: all 0.2s ease-in-out

	&-a.active:after
		transform: rotate(45deg)
		-webkit-transition: all 0.2s ease-in-out
		-moz-transition: all 0.2s ease-in-out
		transition: all 0.2s ease-in-out
		top: 9px
