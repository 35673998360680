/* HEADER  */

.header
	padding-bottom: 10px
	.logo
		img
			@media screen and (max-width: $xxl)
				width: 100px
				transition: width 1s

	&.header_sticky::after
		content: ''
		width: 100%
		position: absolute
		display: block
		left: 0
		bottom: -3px
		border-bottom: 3px solid var(--color-turquoise)
	&.header_sticky
		.logo
			img
				width: 96px
				transition: width 1s
		.btn-header
			opacity: 1	
			transition: opacity 1s	
			transform: translateY(0px)
	.btn-header
		position: absolute	
		right: var(--gutter-horizontal)
		top: 120px
		opacity: 0
		transform: translateY(-150px)
		.button
			padding: 8px 10px
			background: var(--color-orange)
			span 
				color: #fff

/* OVERLAY MENU */	
.opened
	.logo
		pointer-events: none
	.menu-links
		.arrow, .icon_social
			img
				opacity: 1 !important
				transition: opacity 0.1s ease

[data-arts-header-animation='intransition']
	.menu-links
		.arrow, .icon_social
			img
				opacity: 0 !important

.menu-overlay-landing
	.header__menu-column::before
		content:''
		background-image: url(../img/01_home/banner_home.jpg)
		width: 100%
		background-repeat: no-repeat
		background-size: cover
		background-position: center center
		position: absolute
		top: 0
		width: 100%
		height: 100%
		opacity: 0.8
		@media screen and (max-width: $sm)
			opacity: 0.5
	.menu-links
		width: 30%
		display: flex
		align-items: flex-end
		justify-content: flex-end
		@media screen and (max-width: $md)
			width: 100%
			align-items: flex-start
			justify-content: flex-start
		.menu-overlay__heading
			align-items: center !important
		.content-menu
			border-left: 1px solid rgba(#fff,0.6)
			padding-left: 20px
			padding-right: 20px
			flex-direction: column !important
			align-items: flex-start
			margin: 0
			width: auto
			@media screen and (max-width: $md)
				border-left: none
				padding-left: 0px
				border-top: 1px solid rgba(#fff,0.6)
				width: 100% !important
				padding: 0px
				margin-left: 20px
				margin-top: 30px
				padding-top: 30px
			@media screen and (max-width: $xs)
				margin-left: 0px
		.arrow
			margin-top: 0px !important
		.arrow, .icon_social
			margin: 30px 0px
			padding: 0px
			li
				list-style: none
				margin-bottom: 6px
				color: #fff
				font-size: 15px
				a
					color: #fff
				img
					width: 24px
					margin-right: 10px
					opacity: 0
					@media screen and (max-width: $xs)
						width: 20px
		.adress
			padding-left: 0px
			li
				list-style: none

		.icon_social
			img
				width: 20px !important
				@media screen and (max-width: $xs)
					width: 18px !important

	.menu-overlay
		padding-left: 6vw
		width: 70%
		@media screen and (max-width: $md)
			width: 100%
			padding-left: 20px
		@media screen and (max-width: $xs)
			padding-left: 0px
		> li
			margin-bottom: 0px
			margin-top: 35px
		.menu-overlay__heading
			display: flex
			align-items: flex-end
			.small
				font-size: 18px
				line-height: 1
				color: #fff
				top: -8px
				position: relative
				padding-right: 10px

	.division.menu-small
		margin-bottom: 0px !important
		margin-top: 20px !important
		a
			display: block
			width: 100%
		.arts-split-text
			border-top: 1px solid rgba(#fff, 0.6)
			padding-top: 30px
			margin-top: 20px
	.reporte-titulo
		margin-bottom: 0px !important
		a
			display: block
			width: 100%
			pointer-events: none !important
			cursor: default !important
	.menu-small.reporte-anos
		margin-top: 10px !important
		a
			color: var(--color-orange) !important
			margin-right: 16px
			.arts-split-text__line
				border-bottom: 1px solid var(--color-orange) !important

	.menu-small
		margin-top: 16px !important
		margin-bottom: 0px !important
		a
			font-size: 18px
			color: #fff !important

/* DEGRADED */

.full__bottom
	height: 239px
	width: 100%
	position: absolute
	bottom: 0
	z-index: 50
	.degraded
		height: 100%
		width: 100%
		position: absolute
		&.blue
			background: transparent linear-gradient(0deg, #1D0F44 0%, #1D0F44CB 29%, #1D0F4476 63%, #1D0F4400 100%) 0% 0% no-repeat padding-box
		&.white
			background: transparent linear-gradient(0deg, #FFFFFF 0%, #FFFFFFCC 27%, #FFFFFF99 52%, #FFFFFF33 78%, #FFFFFF00 100%) 0% 0% no-repeat padding-box

.full__top
	height: 200px
	width: 100%
	position: absolute
	top: 0
	z-index: 50
	.degraded
		transform: matrix(-1, 0, 0, -1, 0, 0)
		height: 100%
		width: 100%
		position: absolute
		&.white
			background: transparent linear-gradient(0deg, #FFFFFF 0%, #FFFFFFCC 27%, #FFFFFF99 52%, #FFFFFF33 78%, #FFFFFF00 100%) 0% 0% no-repeat padding-box
		&.blue
			background: transparent linear-gradient(0deg, #1D0F44 0%, #1D0F44CB 29%, #1D0F4476 63%, #1D0F4400 100%) 0% 0% no-repeat padding-box
		&.blue-2
			background: transparent linear-gradient(180deg, #162D42 0%, #162D42EA 20%, #162D4294 67%, #162D4200 100%) 0% 0% no-repeat padding-box
		&.blue-3
			transform: none
			background: transparent linear-gradient(180deg, #162D42 0%, #162D42EA 20%, #162D4294 67%, #162D4200 100%) 0% 0% no-repeat padding-box

.full__top_2
	height: 180px
	width: 100%
	position: absolute
	top: 0
	z-index: 50
	.degraded
		transform: matrix(-1, 0, 0, -1, 0, 0)
		height: 100%
		width: 100%
		position: absolute
		&.white
			background: transparent linear-gradient(0deg, #FFFFFF 0%, #FFFFFFCC 27%, #FFFFFF99 52%, #FFFFFF33 78%, #FFFFFF00 100%) 0% 0% no-repeat padding-box



.degraded-left
	height: 100%
	width: 166px
	position: absolute
	left: 0
	z-index: 50
	&.white
		background: transparent linear-gradient(90deg, #FFFFFF 0%, #FFFFFFE2 21%, #FFFFFFBF 43%, #FFFFFF7C 58%, #FFFFFF2B 78%, #FFFFFF00 100%) 0% 0% no-repeat padding-box

.degraded-right
	height: 100%
	width: 140px
	position: absolute
	right: 0
	z-index: 50
	top: 0
	&.white
		transform: matrix(-1, 0, 0, -1, 0, 0)
		background: transparent linear-gradient(90deg, #FFFFFF 0%, #FFFFFFE2 21%, #FFFFFFBF 43%, #FFFFFF7C 58%, #FFFFFF2B 78%, #FFFFFF00 100%) 0% 0% no-repeat padding-box

/* VIDEO */

#bg-video
	-o-object-fit: cover
	object-fit: cover
	width: 100vw
	height: 100vh
	left: 0

/* LINE */

.post-meta__divider.style__1
	margin: 0 !important
	height: 4px !important
	background: var(--color-turquoise) !important
	width: 220px !important
	@media screen and (max-width: $sm)
		height: 3px !important

.post-meta__divider.style__1.style__2
	width: 100% !important
	background: var(--color-orange) !important

.section__headline.fix-center
	@media screen and (max-width: $sm)
		margin-left: auto !important
		margin-right: auto	!important

.section__headline.center
	margin-left: auto
	margin-right: auto

.section__headline.style__1
	background: var(--color-turquoise) !important
	height: 3px !important
	width: 40px
	margin-top: 25px
	&.blue
		background: $color-dark-2 !important
	&.white
		background: #fff !important

.section__headline.style__1.style__2
	width: 100%
	height: 1px !important
	background: var(--color-orange) !important

.section__headline.style__1.style__2.color-turquoise
	width: 100%
	height: 1px !important
	background: var(--color-turquoise) !important

/* FRASES */

.frase
	position: relative
	.img-bg
		background-image: url('../img/mancha_cumplimiento.png')
		background-size: 90%
		width: 700px
		height: 100%
		position: absolute
		background-repeat: no-repeat

	.lazy-wrapper
		margin-right: 20px
		position: relative
		top: 10px
		@media screen and (max-width: $sm)
			display: none
	h4
		padding: 10px 10px 10px 25px
		border-left: 2px solid var(--color-turquoise)
		margin: 0

.frase_2
	.img-bg
		background-image: url('../img/trama2.png')
		background-size: 90%
		background-repeat: no-repeat
		width: 660px
		height: 400px
		position: absolute
		left: 0

	.lazy-wrapper
		margin-left: 20px
		position: relative
		top: 10px
		@media screen and (max-width: $sm)
			display: none
	h4
		padding: 10px 25px 10px 10px
		border-right: 2px solid var(--color-turquoise)
		margin: 0
		@media screen and (max-width: $sm)
			text-align: left
			border-left: 2px solid var(--color-turquoise)
			border-right: none
			padding: 10px 10px 10px 25px

/* VARIOS  */

b, strong
	color: var(--color-turquoise)
	font-weight: bold
.color-orange
	color: var(--color-orange) !important

.color-white
	color: #fff !important

.border-orange
	border-bottom: 3px solid var(--color-orange)

.border-turquoise
	border-bottom: 3px solid var(--color-turquoise)

.line
	&:after
		content: ''
		position: relative
		display: block
		width: 30px
		height: 3px
		margin-top: 20px
		background: var(--color-turquoise)
	&.mt-1:after
		margin-top: 10px

	&.center:after
		margin-left: auto
		margin-right: auto

h6
	span
		color: #fff
		font-weight: 600
		text-decoration: underline
		@include fluid-type(var(--h5-min-font-size), var(--h5-max-font-size))
.h-border
	span
		padding-bottom: 6px
		border-bottom: 3px solid var(--color-turquoise)
.small-italic
	font-size: 17px
	font-style: italic
	span
		color: var(--color-orange)

.padding__1
	.first
		padding-right: 140px
		@media screen and (max-width: $xxl)
			padding-right: 100px
		@media screen and (max-width: $xl)
			padding-right: 80px
		@media screen and (max-width: $lg)
			padding-right: 40px
		@media screen and (max-width: $md)
			padding-right: 15px
			text-align: center
	.last
		padding-left: 40px
		@media screen and (max-width: $xl)
			padding-left: 0px
		@media screen and (max-width: $md)
			padding-left: 15px
			margin-top: 40px

.padding__2
	.first
		padding-right: 120px
		@media screen and (max-width: $xxl)
			padding-right: 100px
		@media screen and (max-width: $xl)
			padding-right: 80px
		@media screen and (max-width: $lg)
			padding-right: 15px
			margin-bottom: 40px
	&.b
		.first
			padding-right: 80px
			@media screen and (max-width: $xxl)
				padding-right: 70px
			@media screen and (max-width: $xl)
				padding-right: 60px
			@media screen and (max-width: $lg)
				padding-right: 40px
			@media screen and (max-width: $md)
				padding-right: 30px
			@media screen and (max-width: $sm)
				padding-right: 15px
				padding-top: 60px

.padding__3
	&.b
		.last
			padding-left: 0px !important
			@media screen and (max-width: $md)
				padding-left: 15px !important
	.first
		padding-right: 60px
		@media screen and (max-width: $xxl)
			padding-right: 50px
		@media screen and (max-width: $xl)
			padding-right: 40px
		@media screen and (max-width: $lg)
			padding-right: 30px
		@media screen and (max-width: $md)
			padding-right: 15px
			padding-bottom: 50px
			text-align: center
	.last
		padding-left: 60px
		@media screen and (max-width: $xxl)
			padding-left: 50px
		@media screen and (max-width: $xl)
			padding-left: 40px
		@media screen and (max-width: $lg)
			padding-left: 30px
		@media screen and (max-width: $md)
			padding-left: 15px
			padding-bottom: 20px
			text-align: center
.padding__4
	.first
		padding-right: 100px
		@media screen and (max-width: $xxl)
			padding-right: 90px
		@media screen and (max-width: $xl)
			padding-right: 80px
		@media screen and (max-width: $lg)
			padding-right: 15px
			padding-bottom: 50px

.bottom_image_text_1
	position: relative
	margin-top: -3% !important
.color-turquoise
	color: var(--color-turquoise) !important

.color-turquoise2
	color: var(--color-turquoise2) !important
	*
		color: var(--color-turquoise2) !important

.trama-1
	background-image: url('../img/mancha_modelo.png')
	background-repeat: no-repeat
	background-position: left bottom
	position: absolute
	bottom: 0
	background-size: 90%
	left: 0
	height: 300px
	width: 400px
	opacity: 0.6
	@media screen and (max-width: $sm)
		height: 240px
		width: 240px

.trama-2
	background-image: url('../img/mancha_biolements.png')
	background-repeat: no-repeat
	background-position: center
	position: absolute
	bottom: 0
	right: 0
	height: 380px
	width: 440px
	background-size: 90%
	&.left
		right: auto
		left: 0
	@media screen and (max-width: $sm)
		height: 240px
		width: 240px

.trama-3
	background-image: url('../img/01_home/mancha_1_home.png')
	background-repeat: no-repeat
	background-position: center
	position: absolute
	top: -10%
	right: 0px
	height: 340px
	width: 500px
	background-size: contain

.trama-5
	background-image: url('../img/01_home/mancha_1_home.png')
	background-repeat: no-repeat
	background-position: center
	position: absolute
	top: -10%
	right: 0px
	height: 340px
	width: 500px
	background-size: contain

.trama-4
	background-image: url('../img/mancha_biolements.png')
	background-repeat: no-repeat
	background-position: center
	position: absolute
	bottom: 0
	left: 0
	height: 300px
	width: 800px
	background-size: 90%
	@media screen and (max-width: $sm)
		height: 240px
		width: 240px

.trama-5
	background-image: url('../img/02_nuestra_identidad/mancha_1.png')
	background-repeat: no-repeat
	background-position: center
	position: absolute
	top: 0
	right: 0px
	height: 340px
	width: 340px
	background-size: contain
	&.left
		left: 0
		right: auto
	&.bottom
		top: auto
		bottom: 0

.trama-6
	background-image: url('../img/02_nuestra_identidad/mancha_2.png')
	background-repeat: no-repeat
	background-position: center
	position: absolute
	bottom: 80px
	left: 0px
	height: 350px
	width: 720px
	background-size: contain

.trama-7
	background-image: url('../img/02_nuestra_identidad/mancha_mapa.png')
	background-repeat: no-repeat
	background-position: center
	position: absolute
	bottom: 140px
	left: 0px
	height: 460px
	width: 220px
	background-size: contain
	&.top
		top: 0
		bottom: auto
		height: 500px
		width: 240px

.trama-8
	background-image: url('../img/02_nuestra_identidad/mancha_equipo_1.png')
	background-repeat: no-repeat
	background-position: center
	position: absolute
	top: 0
	right: 0px
	height: 340px
	width: 420px
	background-size: contain

.trama-9
	background-image: url('../img/02_nuestra_identidad/mancha_equipo_2.png')
	background-repeat: no-repeat
	background-position: center
	position: absolute
	top: 0
	left: 0px
	height: 500px
	width: 660px
	background-size: contain

.trama-10
	background-image: url('../img/03_nuestro_compromiso/mancha_puntolimpio.png')
	background-repeat: no-repeat
	background-position: center
	position: absolute
	top: 0px
	left: 0px
	height: 480px
	width: 540px
	background-size: contain

.trama-11
	background-image: url('../img/03_nuestro_compromiso/mancha_comunidad.png')
	background-repeat: no-repeat
	background-position: center
	position: absolute
	top: 0px
	right: 0px
	height: 360px
	width: 340px
	background-size: contain
	&.left
		left: 0
		right: auto
		bottom: 60px
		top: auto

.trama-12
	background-image: url('../img/03_nuestro_compromiso/mancha_comite.png')
	background-repeat: no-repeat
	background-position: center
	position: absolute
	top: 0px
	left: 0px
	height: 540px
	width: 280px
	background-size: contain

.trama-13
	background-image: url('../img/05_etica_transparencia/mancha_frase_ruben.png')
	background-repeat: no-repeat
	background-position: center
	position: absolute
	top: 0px
	left: 0px
	height: 540px
	width: 280px
	background-size: contain

.menu-fix
	position: absolute
	top: -90px
	@media screen and (max-width: $md)
		top: 60px
#pilares.menu-fix
	top: -40px


#svg-modelo
	position: relative
	overflow: visible
	@media screen and (min-width: $sm)
		#prevencion,
		#competencia,
		#ambiental,
		#seguridad,
		#gobierno
			transition: all .2s ease
			-webkit-transition: all .2s ease
			-o-transition: all .2s ease
			&:hover
				opacity: 0.8

.mapa
	position: relative
	.title
		margin-left: 50px
		margin-bottom: 30px
	svg
		height: 840px
		.zonas
			fill: #1D0F44
			stroke: rgb(#fff, 0.7)
			stroke-width: 0.7
		.pin-1
			fill: #009f98
		.pin-2
			fill: #ffffff

	.leyenda
		position: absolute
		left: 58%
		text-align: left
		&#rm
			top: 40%
		&#biobio
			top: 47%
		&#araucania
			top: 50%
		&#loslagos
			top: 58%
		&#aysen
			top: 70%
		&#magallanes
			top: 82%
		h5
			font-weight: bold
			text-transform: uppercase
			margin: 0 0 6px 0
		.porcentaje
			background: var(--color-turquoise)
			display: inline
			color: #fff
			padding: 0px 10px

	.zonas.hover:hover, .zonas.hover:hover polygon
		cursor: pointer
		fill: var(--color-orange)
	.zonas:active
		fill: var(--color-orange)

/* TITULOS  */	

.title
	.lazy-wrapper
		@media screen and (max-width: $sm)
			width: 24px

.ls-2
	letter-spacing: 2px !important

.lang
	a
		margin-right: 10px
	.active
		color: var(--color-orange) !important
		border-bottom: 1px solid var(--color-orange)

.menu-overlay-landing .menu-small.lang-mobile
	margin-top: 40px !important
	a
		margin-right: 16px
	.active
		color: var(--color-orange) !important
		border-bottom: 1px solid var(--color-orange)

.swiper-button-next::after, .swiper-button-prev::after
	display: none !important

.header-title
	.height-fix
		height: 340px
	.text-center
		@media screen and (max-width: $sm)
			margin-top: 130px
	.degraded
		height: 61px
		width: 100%
		position: absolute
		bottom: 0
		z-index: 50
		background: transparent linear-gradient(0deg, #FFFFFF 0%, #FFFFFFF3 15%, #FFFFFFC3 36%, #FFFFFF00 100%) 0% 0% no-repeat padding-box

.ofertas
	h6
		min-height: 50px
	.border-left
		position: relative
		&:after
			content: ''
			display: block
			height: 100%
			top: 0
			left: 20px
			position: absolute
			background: var(--color-turquoise)
			width: 1px
			@media screen and (max-width: $sm)
				width: calc( 100% - 20px)
				height: 1px
				top: auto
				left: 0
				right: 0
				margin: 0 auto
				bottom: -30px

/* LOGOS */	

.logos_size
	.size
		@media screen and (max-width: $md)
			max-width: 220px
		@media screen and (max-width: $xs)
			max-width: 180px
	.size2
		@media screen and (max-width: $md)
			max-width: 300px
		@media screen and (max-width: $xs)
			max-width: 260px
	.size3
		@media screen and (max-width: $xs)
			max-width: 200px

.pr-xlarge.fix
	@media screen and (max-width: $xl)
		padding-right: 100px !important
	@media screen and (max-width: $lg)
		padding-right: var(--gutter-horizontal) !important

.mt-large.fix
	@media screen and (max-width: $lg)
		margin-top: 0px !important

.certificados
	display: flex
	flex-direction: column
	img
		margin-bottom: 20px
		@media screen and (max-width: $xs)
			max-width: 120px

.img-pilares
	img.ico
		@media screen and (max-width: $md)
			max-width: 60px
		@media screen and (max-width: $sm)
			max-width: 50px
		@media screen and (max-width: $xs)
			max-width: 40px
			margin-top: 10px
	.section_h-700
		@media screen and (max-width: $xs)
			max-height: 70vh
			display: flex
			justify-content: center
			align-items: center

.pointer
	cursor: pointer

.pointer-events-none
	pointer-events: none !important
	cursor: default !important


.submenu
	@media screen and (max-width: $sm)
		display: flex
		flex-direction: column
		align-items: flex-start !important
	a:hover .arts-split-text__line
		background-size: 100% 0px !important
	&:hover
		.content-submenu
			opacity: 1
	.content-submenu
		opacity: 0
		padding-left: 60px
		width: 440px
		position: relative
		top: 22px
		transition: opacity 0.5s
		@media screen and (max-width: $sm)
			padding-left: 30px
			top: 15px
		.btn
			width: 32%
			display: inline-block
			margin-bottom: 10px
			a
				color: #fff
				text-transform: uppercase
				letter-spacing: 0.5px
				font-size: 16px
				@media screen and (max-width: $sm)
					font-size: 15px
				@media screen and (max-width: $sm)
					font-size: 14px	

.modal-open
	.menu-overlay-landing .header__menu-column, .header__burger
		opacity: 0


.menu-overlay
	.underline-hover__target:not(.arts-split-text)
		background-image: linear-gradient(#009F98, #009F98)
		background-size: 0% 2px	
	.underline-hover
		&:hover
			.underline-hover__target
				background-size: 100% 2px
				.arts-split-text__line
					background-size: 100% 2px	








.opened
	.language
		ul
			margin-bottom: 0px
			position: relative
			top: -3px
			+trans1



.language-content
	.language
		position: relative
		right: 105px
		@media screen and (max-width: $md)
			right: 25px
		@media screen and (max-width: $xs)
			right: 17px	
		&::before
			content: ''
			top: -10px
			right: -30px
			position: absolute
			font-weight: 400
			background: #fff
			height: 40px
			width: 1px
			@media screen and (max-width: $md)
				display: none
	ul
		margin-bottom: 0px
		position: relative
		top: 1px
		+trans1
		@media screen and (max-width: $xs)
			top: 0px
		li
			display: inline
			padding-right: 10px
			padding-left: 2px
			position: relative
			&::after
				content: '/'
				top: -1px
				position: absolute
				color: #fff !important
			a
				color: #fff !important
				font-size: 16px
				font-weight: 600
				@media screen and (max-width: $xs)
					font-size: 15px	
		li:last-child
			padding-right: 0px
			&::after
				display: none
		.wpml-ls-current-language
			a
				color: var(--color-turquoise) !important
	.wpml-ls-display
		display: none	



.list-arrow
	list-style: none
	margin: 0
	padding-left: 25px
	li
		margin-bottom: 12px
		position: relative
		a
			@include fluid('font-size',15, 16)	
			color: var(--color-dark-2)
			text-transform: uppercase
			font-weight: 600
		&.active
			a
				color: var(--color-orange)	
			::before
				content:""
				display: block
				position: absolute	
				left: -25px
				top:5px
				background-image: url('../img/flecha.png')
				background-size: 19px auto
				width: 20px
				height: 20px
				background-repeat: no-repeat


body.home
	.header
		.logo__wrapper-img
			display: none
	.header.header_sticky
		.logo__wrapper-img
			display: block
