.form
	width: 100%
.form__submit
	margin-top: 1.5em
.form__col
	margin-top: 0.5em
	margin-bottom: 0.5em
.form__col_submit
	margin-top: 1.5em
	margin-bottom: 0
.form__error, span.wpcf7-not-valid-tip
	display: block
	font-size: 12px
	color: red
	text-align: left
	margin-top: 4px
.form__heading
	margin-top: 0
	margin-bottom: 1em

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing
	border-color: red
	padding: 15px
	margin: 1.5em 0 0
	display: none !important

@media only screen and (max-width: 992px)
	.form__col_submit
		text-align: left


#form
	.gform_wrapper.gravity-theme 
		.gf_step_active
			.gf_step_number
				background: var(--color-orange)
				border: none 
				font-weight: bold
			.gf_step_number
				color: #fff 
			.gf_step_label	
				color: var(--color-turquoise)	
				font-weight: bold
		.gform_next_button, .gform_button	
			background: var(--color-orange)
			color: #fff !important
			font-size: 15px
			margin-top: 20px
			padding: 8px 25px
		.gform_previous_button
			padding: 8px 25px
			font-size: 15px
			color: var(--color-orange)


		.gf_step_label
			padding-left: 10px
		.gf_step_label
			font-weight: normal
			color: #585e6a
			font-size: 16px
		.gf_step_number
			border: 1px solid #585e6a
			font-size: 16px
			color: #585e6a
		.gf_step
			margin-bottom: 15px	

		.gf_page_steps
			border-bottom: 1px solid var(--color-dark-1)
			margin-bottom: 30px
		.gform_validation_errors
			border-radius: 0px
			box-shadow: none !important
		.validation_message
			font-size: 14px	
		.gfield_label
			font-weight: 500
			font-size: 18px	
			margin-top: 8px
			margin-bottom: 6px
		.gchoice
			label
				font-size: 17px
				
		input
			border: 1px solid var(--color-dark-2)
			padding: 5px

		h3
			font-size: 24px
			font-weight: 500
			margin-top: 20px
			margin-bottom: 10px
			border-bottom: 1px solid var(--color-dark-2)
			display: inline-block
		p
			font-size: 16px	
			margin:0px
		.gfield_validation_message
			padding: 4px 10px
			font-size: 13px
		.gform_validation_errors
			h2
				font-size: 15px
		.gfield_required
			font-style: normal		
		::placeholder
			color: var(--color-dark-2) !important
			




.hola
	background: var(--color-dark-2)	
	color: #fff
	padding: 20px
	font-size: 17px
	margin-bottom: 20px

.gfield_description
	padding-top: 5px !important


.gftt-icon
	color: var(--color-dark-2) !important



.gform_wrapper.gravity-theme .chosen-container-single .chosen-single
	background: none !important
	box-shadow: none !important
	border: 1px solid var(--color-dark-2) !important
	color: var(--color-dark-2) !important


.gform_wrapper.gravity-theme .chosen-container-single .chosen-drop
	border-radius: 0px !important
	border: 1px solid var(--color-dark-2) !important


.gform_wrapper.gravity-theme .gf_step_completed .gf_step_number:before
	background: var(--color-dark-2) !important
	border: none !important




.page-id-859	
	#gform_page_1_4
		fieldset:first-child
			padding-top: 0px
		fieldset
			padding-bottom: 20px
			padding-top: 10px
			border-bottom: 1px solid var(--color-dark-2) !important



.page-id-863
	#form
		max-width: 1000px
		margin: 0 auto
