.button
	position: relative
	display: inline-block
	font-size: 13px
	line-height: 1
	letter-spacing: 1px
	font-weight: 500
	white-space: nowrap
	text-transform: uppercase
	cursor: pointer
	box-shadow: none
	padding: 8px 25px
	color: var(--h3-color-dark)
	border: 1.5px solid var(--color-orange) !important
	+trans1
	transition-property: color, background-color, border-color
	@media screen and (max-width: $xs)
		font-size: 12px
	&:hover
		background: var(--color-orange)	
		color: #fff !important


	&.small
		padding: 6px 15px
		font-size: 12px

	
	&:focus
		outline: none
	&:hover
		.button__label-normal
			.button__title
				transform: translate(0%, -100%)
				opacity: 0
			.button__icon
				opacity: 0
				transform: translate(100%, 0%)
		.button__label-hover
			.button__title
				transform: translate(0%, 0%)
				opacity: 1
			.button__icon
				opacity: 1
				transform: translate(0%, 0%)

.button_icon
	display: inline-flex
	border: none
	.button__label
		padding: 0
.button__label-hover
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	.button__title
		transform: translate(0%, 100%)
		opacity: 0
	.button__icon
		transform: translate(-100%, 0%)
		opacity: 0

.button__label-normal
	.button__title
		transform: translate(0%, 0%)
		opacity: 1

.button__title
	display: inline-block
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out
.button__label
	display: block
	padding: 18px 42px

.button__icon
	display: inline-flex
	align-items: center
	justify-content: center
	// width: 60px
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out
	font-size: 75%
.button__icon_before
	margin-right: 0.5em
.button__icon_after
	margin-left: 0.25em

.button_fullwidth
	width: 100%
.button-fullwidth
	.button
		width: 100%


.all-button
	.button 
		margin: 0px 15px 30px 15px	
		min-width: 180px
		color: var(--color-orange)
		justify-content: center
		@media screen and (max-width: $sm)
			min-width: 160px
		@media screen and (max-width: $sm)
			min-width: 140px
		@media screen and (max-width: $xs)
			min-width: 100px
			margin: 0px 15px 20px 15px	
