.section-fullheight
	display: flex
.section-fullheight__inner
	position: relative
	display: flex
	flex-direction: column
	justify-content: center
	width: 100%
	min-height: 100vh


.section-fullheight__inner_mobile
	height: calc(var(--fix-bar-vh, 1vh) * 100)
	min-height: 0vh
.section-fullheight__header
	position: absolute
	top: 0
	left: 0
	bottom: 0
	display: flex
	flex-direction: column
	justify-content: center
	width: 100%
	max-width: 50%
	z-index: 0

@media screen and (max-width: $md)
	.section-fullheight__header
		position: relative
		top: auto
		left: auto
		max-width: 100%
	.section-fullheight__inner_mobile-auto
		height: auto !important
		min-height: 0 !important
@media screen and (min-width: $md - 1)
	.section-fullheight__header
		padding-top: 0 !important
