.equipo
	display: flex
	flex-flow: row wrap
	justify-content: center
	.box_team
		width: 260px
		margin: 10px
		@media screen and (max-width: $md)
			width: 220px
		@media screen and (max-width: $xs)
			width: calc( 50% - 10px)
			margin: 5px
		.position-relative
			height: 340px
			border-bottom: 3px solid var(--color-orange)
			@media screen and (max-width: $md)
				height: 310px
			@media screen and (max-width: $xs)
				height: 280px
			


/* BOX TEAM */	

.box_team
	position: relative
	&.style__2
		margin: 10px 60px 30px !important
		width: 200px !important
		@media screen and (max-width: $xs)
			margin: 10px 20px 30px !important
			width: 40% !important

		.position-relative
			height: auto !important
			border: none !important
			
		img
			border-bottom: 3px solid var(--color-orange)	
		.text-center
			padding-top: 10px	
			p 
				color: var(--color-turquoise)	
			.blue
				color: var(--color-blue) !important	

		
	.degraded
		height: 235px
		width: 100%
		position: absolute
		bottom: 0
		z-index: 1
		transform: matrix(-1, 0, 0, -1, 0, 0)
		background: transparent linear-gradient(180deg, #1D0F44 0%, #1D0F44CB 29%, #1D0F4476 63%, #1D0F4400 100%) 0% 0% no-repeat padding-box
	.text-center
		padding: 20px 10px
		transition: all 0.5s
	.h6
		font-size: 18px
		margin: 0px
		color: #fff
		line-height: 1.2
		margin-bottom: 5px
	p
		line-height: 1
		font-size: 14px
		margin: 0px
		color: #fff
	.text
		opacity: 0
		transition: opacity 0.5s
		height: 0
	a:focus
		outline: 0
	.section__bg
		display: flex
		align-items: flex-end
		justify-content: center
		z-index: 2

	
.box_team.hover
	.icon
		width: 30px
		position: absolute
		top: 50%
		transform: translateY(50%)
		transition: all 0.5s
		opacity: 0
		@media screen and (max-width: $xs)
			width: 22px
	.position-relative
		overflow: hidden
	.of-cover	
		transition: all 0.3s ease-in-out	
	&:hover
		.icon
			opacity: 1
			transform: translateY(-50%)
		.of-cover
			transform: scale(1.03)
				





