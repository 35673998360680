.bg-dark-1
	background-color: $color-dark-1
.bg-dark-2
	background-color: $color-dark-2
.bg-dark-3
	background-color: $color-dark-3
.bg-dark-4
	background-color: $color-dark-4

.bg-light-1
	background-color: $color-light-1
.bg-light-2
	background-color: $color-light-2
.bg-light-3
	background-color: $color-light-3
.bg-light-4
	background-color: $color-light-4

.bg-white-1
	background-color: #fff

.bg-gray-1
	background-color: $color-gray-1
.bg-gray-2
	background-color: $color-gray-2
.bg-gray-3
	background-color: $color-gray-3
.bg-gray-4
	background-color: $color-gray-4

.bg-portfolio-1
	background-color: $color-portfolio-1
.bg-portfolio-2
	background-color: $color-portfolio-2
.bg-portfolio-3
	background-color: $color-portfolio-3
.bg-portfolio-4
	background-color: $color-portfolio-4
.bg-portfolio-5
	background-color: $color-portfolio-5
.bg-portfolio-6
	background-color: $color-portfolio-6
.bg-portfolio-7
	background-color: $color-portfolio-7
.bg-portfolio-8
	background-color: $color-portfolio-8
.bg-portfolio-9
	background-color: $color-portfolio-9
.bg-portfolio-10
	background-color: $color-portfolio-10
.bg-portfolio-11
	background-color: $color-portfolio-11
.bg-portfolio-12
	background-color: $color-portfolio-12

