.section__intro
	.slider-text
		margin-top: 30px
	.scroll__down
		position: absolute
		left: 0
		right: 0
		bottom: 30px
		margin-left: auto
		margin-right: auto
		width: 50px
		z-index: 60
		@media screen and (max-width: $md)
			width: 44px

	.logo__center
		position: absolute
		left: 0
		right: 0
		top: 50%
		margin-left: auto
		margin-right: auto
		width: 230px
		z-index: 99
		transform: translateY(-50%)
	.play__button
		position: absolute
		left: 0
		right: 0
		width: 100px
		height: 100px
		margin-left: auto
		margin-right: auto
		z-index: 60
		transition: opacity 0.2s
		opacity: 1
		&:hover
			opacity: 0.7
			transition: opacity 0.2s
		@media screen and (max-width: $lg)
			width: 90px
			height: 90px
		@media screen and (max-width: $md)
			display: none
	.play__button_2
		.lazy-wrapper
			max-width: 40px !important
			margin-left: 10px
		p
			width: 134px
			margin: 0px
			letter-spacing: 2px

.section__intro
	.width
		width: 650px
		margin-left: 8vw
		@media screen and (max-width: $md)
			padding-left: var(--gutter-horizontal)
			padding-right: var(--gutter-horizontal)
			margin-left: 0px

	.section__headline
		z-index: 99
		@media screen and (max-width: $md)
			display: none
		&.style__left
			left: 0
			width: 6.5vw
			position: absolute
			height: 2px
			top: calc( 50% - 40px)
			margin: 0px !important
		&.style__right
			width: calc(100% - 650px - 8vw + 0px)
			top: calc( 50% - 40px)
			position: relative
			left: 25px

.section__intro.juntos
	.width
		max-width: 460px
		margin-left: 8vw
		@media screen and (max-width: $md)
			padding-left: var(--gutter-horizontal)
			padding-right: var(--gutter-horizontal)
			margin-left: 0px
			max-width: 100%
			width: 100%
			display: flex
			justify-content: center
			img
				width: 400px 
				@media screen and (max-width: $xs)
					width: 300px


	.section__headline
		z-index: 99
		&.style__left
			left: 0
			width: 6.5vw
			position: absolute
			height: 2px
			top: calc( 50% + 30px)
			margin: 0px !important
		&.style__right
			width: calc(100% - 460px - 8vw + 25px)
			top: calc( 50% + 30px)
			position: relative
			left: 25px
