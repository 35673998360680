
//  TAB

.section__tabs
	transition: background-color 0.4s ease
	&.bg-dark-2
		.tablinks
			background: var(--color-dark-2)
			color: #fff

.tabs-ul-content:after
	content: ''
	position: absolute
	width: calc(100% - 100px)
	height: 1px
	background: var(--color-turquoise)
	margin-top: 18px
	z-index: 1
	@media screen and (max-width: $xxl)
		width: calc(100% - 60px)
	@media screen and (max-width: $xl)
		width: calc(100% - 0px)
	@media screen and (max-width: $sm)
		display: none

.tabs
	margin-bottom: 50px
	padding: 0px
	display: inline-flex
	@media screen and (max-width: $sm)
		display: block
		text-align: center
		margin-bottom: 30px
	.tablinks
		list-style: none
		font-size: 17px
		line-height: 1.1
		color: var(--color-dark-2)
		text-transform: uppercase
		letter-spacing: 0
		cursor: pointer
		border: 2px solid var(--color-turquoise)
		padding: 6px 30px
		margin: 0px 20px
		flex-grow: 1
		background: transparent
		transition: all 0.5s ease
		text-align: center
		z-index: 10
		background: #fff
		@media screen and (max-width: $xl)
			font-size: 16px
			margin: 0px 15px
		@media screen and (max-width: $lg)
			margin: 0px 10px
		@media screen and (max-width: $sm)
			margin: 0px auto 14px auto
			width: 94% !important
		br
			@media screen and (max-width: $sm)
				display: none

		&.active
			transition: all 0.5s ease
			cursor: default
			background: var(--color-turquoise)
			color: #fff

.wrapper_tabcontent
	z-index: 1
	position: relative
	opacity: 1
	overflow: hidden
	transition: all 0.4s ease
	top: 0
	text-align: left
	h4
		margin-top: 0px

.tabcontent
	display: none
	padding-bottom: 100px
	@media screen and (max-width: $md)
		padding-bottom: 90px
	@media screen and (max-width: $sm)
		padding-bottom: 70px
	&.active
		display: block
