.section-video
	display: flex
	width: 100%
	flex-direction: column
	align-items: center
	justify-content: center
.section-video__link
	display: block
	margin: auto
	@include fluid('width', 90, 130)
	@include fluid('height', 90, 130)
	border-radius: 100%
	will-change: transform
	font-weight: 500
	color: #fff
	z-index: 60
.section-video__link-inner
	color: var(--color-orange)
	border: 3px solid var(--color-orange)
	border-radius: 100%
	width: 100%
	height: 100%
	display: flex
	align-items: center
	justify-content: center
	transition: all 0.3s
	&:hover
		background: var(--color-orange)
		.section-video__icon
			color: #fff !important
		

.no-touchevents
	.section-video__link-inner[data-arts-cursor-label]:not([data-arts-cursor-label="false"])
		&:hover
			.section-video__icon
				opacity: 0
				visibility: hidden
				transform: translateY(20px)
.section-video__link-inner[data-arts-cursor-label]:not([data-arts-cursor]):not([data-arts-cursor-label="false"])
	&:after
		content: attr(data-arts-cursor-label)
		display: inline-block
		position: absolute
		top: 50%
		left: 50%
		z-index: 50
		transform: translate(-50%, -20px)
		opacity: 0
		visibility: hidden
		+trans1
	&:hover
		&:after
			opacity: 1
			visibility: visible
			transform: translate(-50%, -50%)

.section-video__container
	position: relative
	display: flex
	align-items: center
	justify-content: center
	width: 100%
	// height: 100%
.section-video__icon.material-icons
	+trans1
	@include fluid-type(40, 50)
	> *
		color: #fff !important
