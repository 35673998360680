.section__content_image

	.section-fullheight__inner
		min-height: 940px
		@media screen and ( max-height: 900px ) and (min-width: 991px)
			min-height: 100%
	.slider-projects-fullscreen__content
		height: auto !important
		max-height: none !important

	.content__top
		max-width: 900px
		margin-top: 140px
		padding-bottom: 30px
		@media screen and ( max-height: 900px ) and (min-width: 991px)
			margin-top: 90px
		@media screen and (max-width: $xl)
			margin-bottom: 50px
		@media screen and (max-width: $lg)
			margin-bottom: 60px
		@media screen and (max-width: $md)
			margin-top: 90px
		&.full-1
			max-width: 950px
		&.full-2
			max-width: 1000px
		&.full-3
			max-width: 1050px
		.box
			.img
				margin-bottom: 20px
				.lazy-wrapper
					@media screen and (max-width: $sm)
						width: 76px

				.h2
					margin: 0px
					padding-left: 15px
			.content
				padding-left: 110px
				@media screen and (max-width: $sm)
					padding-left: 0px
				.subheading
					padding-left: 30px
				.section__headline
					height: 100%
					width: 4px
					background: var(--color-turquoise) !important
					@media screen and (max-width: $sm)
						width: 3px
	.content__bottom
		padding-bottom: 140px
		@media screen and ( max-height: 900px ) and (min-width: 991px)
			padding-bottom: 90px
		@media screen and (max-width: $md)
			padding-bottom: 90px
		.z-100
			max-width: 480px
			@media screen and ( max-height: 900px ) and (min-width: 991px)
				max-width: 440px
			@media screen and ( max-height: 800px ) and (min-width: 991px)
				max-width: 400px
			@media screen and (max-width: $sm)
				max-width: none
		.swiper-slide
			@media screen and (max-width: $md)
				margin-left: 140px
				max-width: 390px
			@media screen and (max-width: $sm)
				margin-left: 0px
