.modal-open
	overflow: hidden
	.modal
		overflow-x: hidden
		overflow-y: auto

.modal
	position: fixed
	top: 0
	left: 0
	z-index: 6000
	display: none
	width: 100%
	height: 100%
	overflow: hidden
	outline: 0

.modal__message
	font-size: 18px
.modal-content__wrapper-button
	text-align: center
	.button
		min-width: 200px

.modal__close
	position: absolute
	top: 20px
	right: 20px
	cursor: pointer
	z-index: 60
	@media screen and (max-width: $xs)
		right: 10px
	img
		width: 34px

.modal-dialog
	position: relative
	width: auto
	margin: 0.5rem
	pointer-events: none
	@media screen and (max-width: $md)
			margin: 0
.modal-dialog_container
	max-width: 980px !important

.modal
	&.fade .modal-dialog
		transition: -webkit-transform 0.3s ease-out
		transition: transform 0.3s ease-out
		transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out
		-webkit-transform: translate(0, -50px)
		transform: translate(0, -50px)
	&.show .modal-dialog
		-webkit-transform: none
		transform: none

@media (prefers-reduced-motion: reduce)
	.modal.fade .modal-dialog
		transition: none

.modal-dialog-scrollable
	display: -ms-flexbox
	display: flex
	max-height: calc(100% - 1rem)
	.modal-content
		max-height: calc(100vh - 1rem)
		overflow: hidden
	.modal-header, .modal-footer
		-ms-flex-negative: 0
		flex-shrink: 0
	.modal-body
		overflow-y: auto

.modal-dialog-centered
	display: -ms-flexbox
	display: flex
	-ms-flex-align: center
	align-items: center
	min-height: calc(100% - 1rem)
	&::before
		display: block
		height: calc(100vh - 1rem)
		content: ""
	&.modal-dialog-scrollable
		-ms-flex-direction: column
		flex-direction: column
		-ms-flex-pack: center
		justify-content: center
		height: 100%
		.modal-content
			max-height: none
		&::before
			content: none

.modal-content
	position: relative
	display: -ms-flexbox
	display: flex
	-ms-flex-direction: column
	flex-direction: column
	width: 100%
	pointer-events: auto
	background-clip: padding-box
	outline: 0
	padding: 50px
	@media screen and (max-width: $md)
		padding: 50px 30px
	@media screen and (max-width: $xs)
		padding: 50px 20px


.modal-backdrop
	position: fixed
	top: 0
	left: 0
	z-index: 1040
	width: 100vw
	height: 100vh
	background-color: #000
	&.fade
		opacity: 0
	&.show
		opacity: 0.7

.modal-header
	display: flex
	align-items: center
	justify-content: space-between
	.close
		padding: 1rem 1rem
		margin: -1rem -1rem -1rem auto

.modal-title
	margin-bottom: 0
	line-height: 1.5

.modal-body
	position: relative
	-ms-flex: 1 1 auto
	flex: 1 1 auto

.modal-footer
	display: -ms-flexbox
	display: flex
	-ms-flex-align: center
	align-items: center
	-ms-flex-pack: end
	justify-content: flex-end
	padding: 1rem
	border-top: 1px solid #dee2e6
	border-bottom-right-radius: 0.3rem
	border-bottom-left-radius: 0.3rem
	>
		\:not(:first-child)
			margin-left: .25rem
		\:not(:last-child)
			margin-right: .25rem

.modal-scrollbar-measure
	position: absolute
	top: -9999px
	width: 50px
	height: 50px
	overflow: scroll

@media (min-width: 576px)
	.modal-dialog
		max-width: 1200px
		margin: 1.75rem auto
		@media screen and (max-width: $md)
			margin: 0 auto
	.modal-dialog-scrollable
		max-height: calc(100% - 3.5rem)
		.modal-content
			max-height: calc(100vh - 3.5rem)
	.modal-dialog-centered
		min-height: calc(100% - 3.5rem)
		&::before
			height: calc(100vh - 3.5rem)
	.modal-sm
		max-width: 300px

@media (min-width: 992px)
	.modal-lg, .modal-xl
		max-width: 800px
	.modal__message
		font-size: 24px

@media (min-width: 1200px)
	.modal-xl
		max-width: 1140px

.modal.form
	.modal-content
		padding: 0px
	.modal-body
		padding: 40px 40px 80px 40px
	.border-right
		border-color: var(--color-turquoise)
		@media screen and (max-width: $md)
			border-right: none
			border-bottom: 1px solid var(--color-turquoise)
	.fix-width
		max-width: 340px
		margin: 0 auto
		padding: 50px 0px

	.modal-header
		position: relative
		height: 220px
		.h3
			color: #fff !important
	.degraded
		height: 61px
		width: 100%
		position: absolute
		bottom: 0
		z-index: 50
		&.white
			background: transparent linear-gradient(0deg, #FFFFFF 0%, #FFFFFFF3 15%, #FFFFFFC3 36%, #FFFFFF00 100%) 0% 0% no-repeat padding-box

.modal.image-left
	.modal-dialog
		max-width: 1140px
	.modal-content
		padding: 0px
	.padding
		min-height: 680px
		padding: 100px 60px 100px 60px
		@media screen and (max-width: $sm)
			min-height: auto
			padding: 60px 40px
	img.of-cover
		@media screen and (max-width: $sm)
			height: 180px !important		
	.padding-2
		min-height: 680px
		padding: 100px 60px

.modal.news
	.modal-dialog
		max-width: 1040px
		.width
			max-width: 620px
			margin-left: auto
			margin-right: auto
			padding-top: 20px
			padding-bottom: 50px

.modal.content
	.modal-dialog
		max-width: 1100px
	.trama-7
		right: 0
		left: auto
		height: 500px
		width: 240px
		bottom: 0
	.modal-content	
		padding: 70px
		@media screen and (max-width: $md)
			padding: 60px 30px

#Modelo-1 .modal-content
	background: #1CADE6

#Modelo-2 .modal-content
	background: #22c49e

#Modelo-3 .modal-content
	background: #94c254

#Modelo-4 .modal-content
	background: #054a70

#Modelo-5 .modal-content
	background: #597c23

#Modelo-6 .modal-content
	background: #076fb7

.modal.modelo
	.modal-dialog
		max-width: 1000px
	.modal-content
		background: var(--color-dark-2)
	.icono
		max-width: 120px
		@media screen and (max-width: $md)
			max-width: 100px
		@media screen and (max-width: $xs)
			max-width: 80px	
	.width
		max-width: 620px
		margin-left: auto
		margin-right: auto
	.pdf
		img
			max-width: 16px
			margin-left: 12px
	.line:after
		background: #fff

.modal.logo
	.modal-dialog
		max-width: 960px
	.icono
		max-width: 280px
	.width
		max-width: 620px
		margin-left: auto
		margin-right: auto




	
.modal.noticias
	p
		font-size: 16px