.footer
	color: #fff
	z-index: 100
	padding-top: 160px
	background-size: cover
	.degraded
		height: 151px
		width: 100%
		position: absolute
		top: 0
		z-index: 1
		&.blue
			background: transparent linear-gradient(180deg, #1D0F44 0%, #1D0F44EA 20%, #1D0F4494 52%, #1D0F4415 84%, #1D0F4400 100%) 0% 0% no-repeat padding-box
		&.white
			background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFFF3 15%, #FFFFFFE2 36%, #FFFFFF00 100%) 0% 0% no-repeat padding-box
	.widgettitle
		display: block
		font-size: 18px
		line-height: 1
		margin-top: 0
		margin-bottom: 15px
		@media screen and (max-width: $lg)
			margin-top: 30px
			font-size: 17px
			border-bottom: 1px solid rgba(#fff, 0.6)
			display: inline-block
		@media screen and (max-width: $xs)
			font-size: 16px
			
		&:after
			display: none
	.content
		p, a
			font-size: 15px
			color: #fff
			margin: 0px !important
			padding-left: 10px
			@media screen and (max-width: $lg)
				padding-left: 0px
				margin-bottom: 5px
			@media screen and (max-width: $md)
				margin-right: 20px !important
				margin-bottom: 10px !important
.footer__divider
	display: block
	position: absolute
	top: 0
	left: calc(var(--bs-gutter-x) / 2)
	right: calc(var(--bs-gutter-x) / 2)
	height: 1px
	padding-left: 0
	padding-right: 0
	width: auto
	background-color: $color-border-dark

.social_footer
	a
		width: 100px
		margin-bottom: 6px
	p
		font-size: 15px

.logo__footer
	@media screen and (max-width: $xl)
		width: 110px
	@media screen and (max-width: $md)
		margin-bottom: 30px
