.slider
	max-width: 100%
	width: 100%
	height: 100%
	.swiper-wrapper > .swiper-slide:first-of-type
		.slider__slide-border
			display: none
	img
		user-select: none
		pointer-events: none
.slider__wrapper-button
	display: inline-block
.slider_vertical-centered
	.swiper-wrapper
		align-items: center !important
.slider__slide-border
	display: block
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 1px
	background-color: $color-border-light
.slider__overlay
	z-index: 10
.slider__thumb-image
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	opacity: 0
	z-index: 0
	+trans1
.slider__thumb-content
	position: relative
	display: flex
	align-items: center
	width: 100%
	height: 100%
	padding: 40px
	z-index: 60
.slider__thumb-heading
	margin-top: 0
	margin-bottom: 0
	color: $color-gray-3 !important
	+trans1
.slider__thumb
	position: relative
	background-color: #fff
	transition: background-color 0.3s ease
	overflow: hidden
	&.swiper-slide-thumb-active
		background-color: #f0f0f0
		.slider__thumb-heading
			color: $color-dark-1 !important
.slider__counter
	line-height: 1
.slider__counter_current
	margin-bottom: 0px
	color: var(--color-dark-4)
.slider__counter_total
	color: var(--color-gray-1)
.slider__arrow
	width: 50px
	height: 50px
	display: inline-flex
	align-items: center
	justify-content: center
	cursor: pointer
	outline: none
	transition: color 0.3s ease
	&.swiper-button-disabled
		user-select: none
		opacity: .3
		pointer-events: none !important
.slider__wrapper-arrows
	height: 24px
	width: 120px
	display: flex
	align-items: center
	justify-content: space-between
	padding-left: 0
	padding-right: 0
.slider__wrapper-arrows_right
	margin-right: -16px
.slider__wrapper-arrows_vertical
	width: 24px
	height: 120px
	flex-direction: column
	margin-left: auto
.slider__slide-centered
	display: inline-flex
	flex-direction: column
	justify-content: center
	align-items: flex-start
.slider__scrollbar
	position: relative
	display: inline-block
	background-color: var(--color-gray-4)
	+trans1
	z-index: 50
.slider__scrollbar_vertical
	height: 200px
	width: 2px
	margin-top: auto
	margin-bottom: auto
.slider__scrollbar_horizontal
	width: 200px
	height: 2px
	margin-top: auto
	margin-bottom: auto
	.slider__scrollbar-handle
		height: 100%
		&:before
			top: auto
			transform: translateX(-50%)
			top: 0
			left: 50%
			right: auto
			margin: 8px 0 0
.slider__scrollbar_horizontal_long
	width: 360px
	.slider__scrollbar-handle
		&:before
			display: none
.slider__scrollbar-handle
	position: relative
	background-color: var(--color-gray-3)
	color: var(--color-gray-3)
	+trans1
	&:before
		content: attr(data-content)
		display: inline-block
		@extend .small
		position: absolute
		top: 50%
		transform: translateY(-50%)
		right: 100%
		white-space: nowrap
		margin-right: 20px
.slider__wrapper-scrollbar
	z-index: 50
.slider__text
	max-width: 500px
.swiper-wrapper_transition
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important
.slider__arrow_absolute
	position: absolute
	z-index: 60
// .slider__arrow
// 	z-index: 60
// 	outline: none
// 	&:focus, &:hover
// 		outline: none
// 	&.swiper-button-disabled
// 		pointer-events: none
// 		opacity: 0
// 		visibility: hidden
.container
	.slider__arrow_left
		transform: translateX(-150%) translateY(-50%)
		left: 0
	.slider__arrow_right
		transform: translateX(150%) translateY(-50%)
		right: 0
.slider__arrow_left
	top: 50%
	transform: translateX(-50%) translateY(-50%)
	left: var(--gutter-horizontal)
.slider__arrow_right
	top: 50%
	transform: translateX(50%) translateY(-50%)
	right: var(--gutter-horizontal)

@media screen and (max-width: $xxl)
	.slider__counter_current
		margin-bottom: 1em
@media screen and (max-width: $md)
	.slider__scrollbar_vertical
		height: 140px
	.slider__scrollbar_horizontal
		width: 140px
	.slider__scrollbar-handle
		&:before
			font-size: 11px !important
			margin-right: 10px
	.slider__wrapper-arrows_vertical
		width: 80px
		height: 80px
	.slider__wrapper-arrows
		width: 80px


.slider__wrapper-counter
	@media screen and (max-width: $md)
		display: none !important
	.sep 
		padding: 0 5px
		position: relative
		top: 0px
		font-size: 18px
		line-height: 1
	
