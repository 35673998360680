.swiper
	position: relative
	.swiper-wrapper
		padding-bottom: 60px
	.swiper-button-next, .swiper-button-prev
			width: 36px	
	&.nav_1
		.swiper-button-prev
			transform: translateX(-15px)	
		.swiper-button-next
			transform: translateX(15px)	
		.swiper-button-next, .swiper-button-prev
			width: 40px	
			@media screen and (max-width: $xs)
				width: 34px	
	&.nav_2
		.swiper-button-next, .swiper-button-prev
			width: 40px	
			@media screen and (max-width: $xs)
				width: 34px	
		.swiper-slide 
			padding: 0px 80px	
			@media screen and (max-width: $md)
				padding: 0px
				
			
		
			
	&.test
		.swiper-slide
			padding: 0px 40px

		.swiper-slide:not(.swiper-slide-active)
			opacity: 0
		.swiper-pagination
			bottom: -10px
			.swiper-pagination-bullet
				background: #fff !important
		.swiper-button-next, .swiper-button-prev
			width: 40px

.swiper-pagination
	bottom: 0px !important
	margin-top: 100px !important
	z-index: 99
	.swiper-pagination-bullet
		background: $color-dark-2


[data-arts-theme-text=light]
	.swiper-pagination-bullet
		background: #fff



[data-arts-theme-text=dark]
	.swiper-pagination-bullet
		background: #fff		

.swipperIcons
	.swiper-pagination
		position: relative	
		margin-top: 20px !important	