/* BOX READ MORE */

.box_readmore
	.button
		opacity: 0
		transition: opacity 0.3s
	.section__bg
		transition: background-color 0.3s linear
	a:focus
		outline: 0

	a:hover
		.button
			opacity: 1
		.section__bg
			background: rgba(#1D0F44, 0.5)
			transition: background-color 0.3s linear

/* BOX ICONS */	

.box_icons
	.degraded
		height: 100%
		width: 100%
		position: absolute
		top: 0
		z-index: 1
		background: transparent linear-gradient(180deg, #1D0F44 0%, #1D0F44EA 20%, #1D0F4494 67%, #1D0F4400 100%) 0% 0% no-repeat padding-box
	.icon
		width: 90px
		@media screen and (max-width: $md)
			width: 80px
	.text-center
		padding: 40px
		transition: all 0.5s
	.h5
		margin-top: 10px
	.text
		opacity: 0
		transition: opacity 0.5s
		height: 0
	a:focus
		outline: 0
	.position-relative_, .of-cover_
		min-height: 640px
	.section__bg
		z-index: 2
		&:hover
			.text
				opacity: 1
			.text-center
				transform: translateY(-100px)

			

.box_testimonial
	background: #fff
	.content
		padding: 20px
		min-height: 280px




.numbers
	display: flex
	margin-top: 30px
	.number
		width: 50px
		@media screen and (max-width: $md)
			width: 40px
	.content
		width: calc( 100% - 50px)
		padding-bottom: 30px
		border-bottom: 1px solid rgba(#fff, 0.6)
		@media screen and (max-width: $md)
			width: calc( 100% - 40px)
		p
			@extend .small_2


body .cita_content
	@media screen and (max-width: $sm)
		padding-left: 16vw
		padding-right:16vw
	.of-cover 
		object-fit: contain !important
		height: auto !important


.cita
	position: relative
	> p
		@extend .h6
		margin-top: 0px
	&::after
		content: '"'
		font-size: 80px
		color: rgba(#009F98, 0.7)
		position: absolute
		font-style: italic
	&::after
		left: -40px
		top: -25px




